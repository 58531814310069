<template>
  <div class="page-user" v-bind="getAllData">User</div>
</template>

<script>
import store from "../../store/index.js";
import global from "../../assets/js/global";
export default {
  data() {
    return {
      loading: null,
      locale: null,
    };
  },
  methods: {
    getdataLang: async function () {
      this.locale = await store.getters["localeLang"];
    },
  },
  computed: {
    getAllData: async function () {
      this.loading = true;
      await this.getdataLang();
      this.loading = false;
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/vars.scss";
</style>
